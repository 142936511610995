<template>
    <template v-if="this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'forget' || this.checkConfirm()">
        <router-view/>
        <!-- <ThemeToggleButton :isDark="isDark" @toggle-theme="toggleTheme" /> -->
    </template>
    <template v-if="this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'forget' && !this.checkConfirm()">
        <div class="bg-white dark:bg-black flex items-start w-screen h-screen overflow-auto text-black dark:text-white">
            <MenuComponent v-if="showNavMenu"/>
            <MenuCompanieComponent v-if="!showNavMenu && !this.checkAdmin()"/>
            <MenuAdminComponent v-if="!showNavMenu && this.checkAdmin()"/>
            <div class="flex-1" :class="{ 'h-screen overflow-y-auto': !showNavMenu }">
                <HeaderComponent :isDark="isDark" @toggle-theme="toggleTheme" />
                <router-view/>
            </div>
        </div>
    
        <SupportComponent v-if="showSupport()" />
    </template>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import HeaderComponent from './components/HeaderComponent.vue';
import MenuComponent from './components/MenuComponent.vue'
// import ThemeToggleButton from './components/ThemeToggleButton.vue';
import MenuCompanieComponent from './components/MenuCompanieComponent.vue';
import MenuAdminComponent from './components/MenuAdminComponent.vue';
import SupportComponent from './components/SupportComponent.vue';

export default {
    name: "App",
    components: {
        MenuComponent,
        MenuCompanieComponent,
        MenuAdminComponent,
        HeaderComponent,
        // ThemeToggleButton,
        SupportComponent
    },
    data() {
        return {
            showNavMenu: true
        };
    },
    computed: {
        ...mapGetters(["isDark","showMenu"]),
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        ...mapMutations(["setShowMenu"]),
        toggleTheme() {
            this.$store.dispatch('toggleTheme');
            document.documentElement.classList.toggle('dark', this.isDark);
        },
        setShowNavMenu() {
            if (this.$route.path == '/' || this.$route.path == '/auth' || this.$route.path == '/config') {
                this.showNavMenu = true
            } else {
                this.showNavMenu = false;
                if(this.showMenu) {
                    this.setShowMenu(false)
                }
            }
        },
        setFavicon(iconUrl) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = iconUrl;
        },
        checkLogin() {
            if(this.currentUser) {
                if (this.currentUser.expiresAt && Date.now() > parseInt(this.currentUser.expiresAt, 10)) {
                    this.$utils.toast("Sua sessão expirou, faça login novamente!", {
                        theme: "auto",
                        type: "error"
                    });

                    setTimeout(() => {
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }, 5000);
                }
            }
        },
        checkConfirm() {
            const pathname = window.location.pathname;

            if (pathname.includes("/confirm/")) {
                return true
            } else {
                return false
            }
        },
        showSupport() {
            if (!this.$route.path.includes('ia/chat')
                && !this.$route.path.includes('ia/coder')
                && !this.$route.path.includes('ia/moderation')
                && !this.$route.path.includes('ia/voiceover')
                && !this.$route.path.includes('ia/transcriber')
                && !this.$route.path.includes('ia/imagine')
                && !this.$route.path.includes('ia/imageeditor')
                && !this.$route.path.includes('ia/productphotos')
            ) {
                return true
            } else {
                return false
            }
        },
        checkAdmin() {
            const pathname = window.location.pathname;

            if (pathname.includes("/admin")) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        this.setShowNavMenu();
        const theme = (this.isDark) ? 'dark' : 'light';
        document.documentElement.classList.add(theme);
        this.setFavicon('/favicon.png');
        document.title = 'HUB - LW';
    },
    watch: {
        '$route'() {
            this.setShowNavMenu();
            this.checkLogin();
        }
    }
}
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap";
@import "https://unpkg.com/tippy.js@6/dist/tippy.css";

* {
    font-family: "Inter", sans-serif;
}
</style>
