<template>
    <div class="p-6 flex justify-between items-center border-b border-gray-300 dark:border-gray-600">
        <button class="xl:hidden border border-gray-700 w-8 h-8 rounded-lg flex-center" @click="setShowMenu(true)" v-if="showButtonMenu()">
            <font-awesome-icon :icon="['fas', 'bars']" />
        </button>
        <p class="">
            Olá 
            <span class="text-purple-400" v-if="this.currentUser">{{ this.currentUser.name.split(' ')[0] }}</span>
            <span class="hidden xl:inline">, seja bem vindo</span>
        </p>
        <div class="flex items-center space-x-4">
            <!-- <button
                @click="toggleTheme"
                @mouseenter="showTooltip"
                @mouseleave="hideTooltip"
            >
                <font-awesome-icon :icon="['fas', 'moon']" v-if="!isDark" />
                <font-awesome-icon :icon="['fas', 'sun']" v-if="isDark" />
            </button> -->
            <img :src="this.getImage()" alt="user" class="w-8 h-8 rounded-full cursor-pointer" ref="showUserMenu">
        </div>
    </div>
    <div class="absolute top-16 right-6 bg-neutral-700 rounded-md z-10 overflow-hidden" v-if="showUserMenu" @click="showUserMenu = false" ref="userMenu">
        <div class="flex flex-col">
            <router-link to="/config" class="flex items-center gap-2 cursor-pointer hover:bg-neutral-800 px-4 py-2 rounded-md">
                <font-awesome-icon :icon="['fas', 'gear']" />
                <span>Editar conta</span>
            </router-link>
            <div class="flex items-center gap-2 cursor-pointer hover:bg-red-600 px-4 py-2 rounded-md" @click="logOut">
                <font-awesome-icon :icon="['fas', 'right-from-bracket']" />
                <span>Sair</span>
            </div>
        </div>
    </div>
    <!-- <div v-if="tooltipVisible" class="absolute top-14 right-2 p-2 text-xs text-white bg-gray-800 rounded">
        Alternar para {{ isDark ? 'Light' : 'Dark' }} Mode
    </div> -->
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "HeaderComponent",
    props: {
        isDark: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            tooltipVisible: false,
            showUserMenu: false
        };
    },
    computed: {
        ...mapGetters(["apiURL"]),
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        ...mapMutations(["setShowMenu"]),
        getImage() {
            if (!this.currentUser) {
                return this.$utils.generateImage("User", this.isDark);
            } else if (this.currentUser.image == "default.png") {
                return this.$utils.generateImage(this.currentUser.name, this.isDark);
            } else {
                return `${this.apiURL}${this.currentUser.image}`
            }
        },
        toggleTheme() {
            this.$emit('toggle-theme');
        },
        showTooltip() {
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
        showButtonMenu() {
            if (this.$route.path == '/' || this.$route.path == '/auth' || this.$route.path == '/config') {
                return false
            } else {
                return true
            }
        },
        logOut() {
            this.showUserMenu = false;
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
        closeMenuOnClickOutside(event) {
            if (this.$refs.showUserMenu && this.$refs.showUserMenu.contains(event.target)) {
                this.showUserMenu = !this.showUserMenu;
            } else if (this.$refs.userMenu && !this.$refs.userMenu.contains(event.target)) {
                this.showUserMenu = false;
            }
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }

        document.addEventListener('click', this.closeMenuOnClickOutside);
    },
    unmounted() {
        document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
}
</script>