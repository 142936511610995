<template>
    <div 
        class="fixed inset-0 z-10 px-5 py-6 xl:w-72 xl:static flex-col h-full bg-neutral-900 border-r border-neutral-600 overflow-y-auto select-none xl:flex"
        :class="(this.showMenu) ? 'flex z-20' : 'hidden'"
    >
        <button class="xl:hidden w-8 h-8 flex-shrink-0 flex-center ml-auto mb-4 border border-gray-700 rounded-lg" @click="setShowMenu(false)">
            <font-awesome-icon :icon="['fas', 'xmark']" />
        </button>

        <div class="flex flex-col items-center mb-8">
            <img src="/logo-white.png" alt="Logo da Empresa" class="w-40 mb-2" />
        </div>

        <ul class="space-y-4 mb-4">
            <template v-for="(item, index) in menu" :key="index">
                <li class="text-gray-300 font-bold flex items-center cursor-pointer" @click="activeMenu(index)" v-if="(item.requiredCheck) ? check(index) : true">
                    <font-awesome-icon :icon="item.icon" />
                    <span class="ml-4">{{ item.name }}</span>
                    <font-awesome-icon :icon="['fas', 'chevron-up']" class="text-sm transition-transform ml-auto" :class="{ 'rotate-180' : !item.active }" />
                </li>
                <section class="flex flex-col items-stretch" v-if="(item.requiredCheck) ? check(index) : true">
                    <button 
                        v-for="(link, linkindex) in item.links" :key="linkindex" v-show="item.active"
                        :class="{ 
                            'px-3 py-3 rounded-xl text-sm text-left border-b-2 border-purple-800 text-white bg-purple-700': isActive(index, linkindex),
                            'text-gray-300 px-3 py-3 rounded-xl text-sm text-left': !isActive(index, linkindex)
                        }"
                        @click="toRoute(index, linkindex)"
                    >{{ link.name }}</button>
                </section>
            </template>
        </ul>
        <button class="mt-auto flex items-center h-14 text-sm p-4 space-x-4 bg-neutral-800 rounded-lg border border-neutral-600 text-left text-white" @click="$router.push('/')">
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span>Voltar</span>
        </button>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "MenuCompanieComponent",
    data() {
        return {
            menu: [
                {
                    name: "Dashboard",
                    icon: ['fas', 'house'],
                    active: true,
                    requiredCheck: false,
                    links: [
                        {
                            name: 'Resumo',
                            route: ''
                        },
                        {
                            name: 'Planos',
                            route: '/credits'
                        },
                        {
                            name: 'Custo de Uso',
                            route: '/pricing'
                        }
                    ]
                },
                {
                    name: "SEO",
                    icon: ['fas', 'chart-line'],
                    active: false,
                    requiredCheck: false,
                    links: [
                        {
                            name: 'Relatórios',
                            route: '/seo/reports'
                        },
                        {
                            name: 'Ferramentas',
                            route: '/seo/tools'
                        }
                    ]
                },
                {
                    name: "Soluções IA",
                    icon: ['fas', 'robot'],
                    active: false,
                    requiredCheck: false,
                    links: [
                        {
                            name: 'Início',
                            route: '/ia'
                        },
                        {
                            name: 'Histórico',
                            route: '/ia/chats'
                        }
                    ]
                },
                {
                    name: "WhatsApp",
                    icon: ['fab', 'whatsapp'],
                    active: false,
                    requiredCheck: false,
                    links: [
                        {
                            name: 'Dispositivos',
                            route: '/wpp/devices'
                        },
                        {
                            name: 'Enviar Mensagem',
                            route: '/wpp/sendmsg'
                        },
                        {
                            name: 'Respostas Automáticas',
                            route: '/wpp/autoreply'
                        },
                        {
                            name: 'Caixa de Saída',
                            route: '/wpp/outbox'
                        },
                        {
                            name: 'Recorrente',
                            route: '/wpp/recurring'
                        },
                        {
                            name: 'Agenda',
                            route: '/wpp/phonebook'
                        }
                    ]
                },
                {
                    name: "Equipe",
                    icon: ['fas', 'user-gear'],
                    active: false,
                    requiredCheck: false,
                    links: [
                        {
                            name: 'Minha Equipe',
                            route: '/team'
                        },
                        {
                            name: 'Auditoria',
                            route: '/audits'
                        }
                    ]
                },
                {
                    name: "Configurações",
                    icon: ['fas', 'gears'],
                    active: false,
                    requiredCheck: true,
                    check: "userId",
                    links: [
                        {
                            name: 'Editar Empresa',
                            route: '/edit'
                        }
                    ]
                },
                {
                    name: "Criador de APP",
                    icon: ['fas', 'mobile-screen-button'],
                    active: false,
                    requiredCheck: true,
                    check: "app",
                    links: [
                        {
                            name: 'Criar APP',
                            route: '/app'
                        }
                    ]
                },
                {
                    name: "Suporte",
                    icon: ['fas', 'headset'],
                    active: false,
                    links: [
                        {
                            name: 'Integrações',
                            // route: '/integrations'
                            route: 'https://loomisweb.com.br/integracoes'
                        },
                        {
                            name: 'Documentação',
                            route: 'https://docs.loomisweb.com.br/'
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapGetters(["companieId","companieData","showMenu", "services"]),
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        ...mapMutations(["setShowMenu"]),
        activeMenu(index) {
            if (this.menu[index]) {
                this.menu[index].active = !this.menu[index].active;
            }
        },
        isActive(index, linkindex) {
            if (this.menu[index]) {
                const item = this.menu[index];

                if (item.links[linkindex]) {
                    const link = item.links[linkindex];

                    return this.$route.path === `/companies/${this.companieId}${link.route}`
                }
            }
        },
        toRoute(index, linkindex) {
            if (this.menu[index]) {
                const item = this.menu[index];

                if (item.links[linkindex]) {
                    const link = item.links[linkindex];

                    if (link.route.includes("http")) {
                        const anchor = document.createElement('a');
                        anchor.href = link.route;
                        anchor.target = "_blank";
                        anchor.rel = "noopener noreferrer";
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    } else {
                        return this.$router.push(`/companies/${this.companieId}${link.route}`)
                    }
                }
            }
        },
        check(index) {
            if (this.menu[index]) {
                const menu = this.menu[index];

                if (menu.check === "app") {
                    if (this.companieData.Product && this.companieData.Product.app) {
                        return true
                    }
                }

                if (menu.check === "userId") {
                    if (this.currentUser && this.currentUser.id === this.companieData.userId) {
                        return true
                    }
                }

                return false
            } else {
                return false
            }
        },
        addServicesMenu() {
            let index = -1

            for (let i = 0; i < this.menu.length; i++) {
                const menu = this.menu[i];
                if (menu.name == "Status e Serviços") {
                    index = i;
                }
            }

            if (index !== -1) {
                let menu = this.menu[index];
                
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];
                    menu.links.push({
                        name: service.name,
                        route: `/services/${service.index}`
                    })
                }

                this.menu[index] = menu;
            }
        }
    },
    mounted() {
        this.addServicesMenu()
    }
}
</script>